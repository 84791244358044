import { registerTemplaterViews } from '@rcg/views';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9edXVQRmBeU0N+W0c=');
registerTemplaterViews();

const actionAppBaseHref = '/act';

const rcgWindow = window as unknown as {
  __rcg_action_app_base_href?: string;
};

(async () => {
  if (window.location.pathname.startsWith(actionAppBaseHref)) {
    rcgWindow['__rcg_action_app_base_href'] = actionAppBaseHref;

    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { bootstrap } = await import('@rcg/app/action/main');
    await bootstrap();
  } else {
    await import('./sentry');

    const { enableProdMode } = await import('@angular/core');
    const { platformBrowserDynamic } = await import('@angular/platform-browser-dynamic');

    const { environment } = await import('./environments/environment');
    const { AppModule } = await import('./app/app.module');
    const { initForms } = await import('@rcg/forms/utils/supported-forms-utils');

    if (environment.production) {
      enableProdMode();
    }

    initForms(environment.tag, environment.production);

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  }
})();
